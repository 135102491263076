import React from "react";

export default function Navbar() {
  return (
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand" href="#home">
          <img
            className="original-logo"
            alt="logo"
            src={require("../assets/Original Logo.PNG")}
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fa-solid fa-bars" style={{ color: "#fff" }}></i>
        </button>
        <div class="collapse navbar-collapse nav-wrapper" id="navbarNav">
          <ul class="navbar-nav navitem-list">
            <li class="nav-item">
              <a class="nav-link " aria-current="page" href="#home">
                Home
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#about">
                About
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#menu">
                Menu
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#news">
                News
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
