import React from "react";

export default function Background() {
  return (
    <div className="background">
      <img
        className="thevine-bg img-fluid"
        alt="theVineBG"
        src={require("../assets/TheVineWall.png")}
      />
    </div>
  );
}
