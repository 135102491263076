import React from "react";
import AboutText from "../componets/aboutText";

export default function About() {
  return (
    <section id="about" className="container-fluid">
      <div className="container">
        <AboutText />
      </div>
    </section>
  );
}
