import React from "react";

export default function Footer() {
  return (
    <div className="footer container-fluid">
      <div className="container">
        <div className="div-wrapper">
          <p className="text-wrapper">
            &copy;2023 All Rights Reserved The Vine of New Brunswick
          </p>
        </div>
      </div>
    </div>
  );
}
