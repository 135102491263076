import React from "react";

export default function Contact() {
  return (
    <section id="contact" className="contianer-fluid">
      <div className="container">
        <div class="contact-wrapper">
          <div class="vine-img">
            <img
              src={require("../assets/vineplant.png")}
              alt="Vine plant"
              className="contact-img"
            />
          </div>
          <div className="container">
            <div class="map-img">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1961.6008690118272!2d-74.45666071138947!3d40.501017238239506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c7073b2f9835%3A0x56af12f44c57fb75!2sThe%20Vine%20of%20New%20Brunswick!5e0!3m2!1sen!2sus!4v1694180945406!5m2!1sen!2sus"
                title="Google Map"
                allowfullscreen="false"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div class="contact-text">
              <h1>Contact Us</h1>
              <div className="container contact-info text-wrap">
                <i class="fa-solid fa-phone-volume"></i>
                <br />
                <p>+1 908 441 7108</p>
                <br />
                <i class="fa-solid fa-envelope"></i>
                <br />
                <p>TheVineofNewBrunswick@gmail.com</p>
                <br />

                <p>75 Morrell St, New Brunswick, NJ 08901</p>
              </div>
              <div className="container contact-info text-wrap">
                <h2>Like &amp; Follow Us</h2>
                <p className="ig-link">
                  <a href="https://ig.me/m/thevineofnewbrunswick/">
                    {" "}
                    &#64;thevineofnewbrunswick
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
