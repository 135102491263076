import React from "react";

import Background from "../componets/background";
import Navbar from "../componets/navbar";

export default function Head() {
  return (
    <div id="home" className="container-fluid">
      <div className="container">
        <Navbar />

        <Background />
      </div>
    </div>
  );
}
