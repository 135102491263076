import React from "react";

export default function AboutText() {
  return (
    <section className="contianer-fluid about-section">
      <div className="box">
        <h1 className="about-title">
          OUR
          <br />
          ROOTS..
        </h1>
        <p className="about-text">
          <span className="text-wrapper">
            The Vine of New Brunswick is a trendy sports bar with historic
            roots. Previously known as Frank n Sam, Park Cafe and Kelly’s
            Korner, The Vine is a new take on good food, great cocktails, cold
            beers &amp; excellent service.
            <br />
            <br />
            Our name, The Vine comes from the unforgettable connections we have
            made throughout our journey and{" "}
          </span>
          <span className="span">the</span>
          <span className="text-wrapper">
            {" "}
            hope of being the vine that connects the past to the present, South
            to North Jersey (since we are Central Jersey), and New Brunswick to
            the world. A modern twist at a renowned location in the Sixth Ward
            of New Brunswick.
            <br />
            <br />
            Come for sports, music, good conversations, and signature cocktails.
            The Vine is located in the heart of Rutgers, surrounded by RWJ, St.
            Peter’s, Johnson &amp; Johnson and many other excellent businesses
            and attractions. The ambiance is earthy, modern, with old school
            charm. Whether you are a first responder, student, sports
            enthusiast, music lover or a foodie, come and connect at The Vine.
          </span>
        </p>
      </div>
      <div className="wrapper-img">
        <img
          src={require("../assets/vineBuilding.png")}
          alt="The Vind Building"
          className="img-fluid vine-building"
        />
      </div>
    </section>
  );
}
