import "./App.css";
import Head from "./pages/head";
import About from "./pages/About";
import Menu from "./pages/menu";
import News from "./componets/news";
import Contact from "./pages/contact";
import Footer from "./componets/footer";

function App() {
  return (
    <div className="container-fluid">
      <Head />
      <About />
      <Menu />
      <News />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
