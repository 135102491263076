import React from "react";

export default function Drinks2() {
  return (
    <div class="container-fluid d-flex justify-content-center align-items-center">
      <img
        src={require("../assets/drinks.png")}
        alt="Drink Menu"
        className="image-fluid menu-img object-fit-fill"
      />
    </div>
  );
}
