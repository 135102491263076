import React from "react";

export default function News() {
  return (
    <section className="container-fluid news-section" id="news">
      <div className="container-fluid ">
        <div className="container events-block">
          <h1 className="event-title">
            News&amp;
            <br />
            Events
          </h1>
          <div className="row algin-items-center">
            <div className="col event-gallery">
              <img src={require("../assets/happyhour.png")} alt="an event" />
            </div>
            <div className="col event-gallery">
              <img src={require("../assets/brunch.png")} alt="an event" />
            </div>
            <div className="col event-gallery">
              <img src={require("../assets/ladiesnight.png")} alt="an event" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
